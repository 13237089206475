import React from 'react'
import { ServiceCardBox } from './Styled'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ContactButton } from '../../../Styled/CustomElements'
import { ServiceCardImg, WhatsappBtn } from '../../../Styled/AllImages'
import { Box, HeadingStyled, LazyImage, TextStyled } from '../../../Styled/Elements'

const ServiceCard = () => {
    const { t } = useTranslation()
    const WhatsApp = () => {
        const phoneNumber = '+37455724095';
        const message = 'Hello, Quantum Digtal';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <ServiceCardBox className='container my-5'>
            <Row className='card'>
                <Col>
                    <Box className='service-card-wrap'>
                        <Box className='service-img-wrap'>
                            <LazyImage src={ServiceCardImg} />
                        </Box>
                        <Box className='service-card-content'>
                            <HeadingStyled className='heading-text-response' size='32px' color='var(--white)'>{t('Transforming')}</HeadingStyled>
                            <TextStyled color='var(--white)'>{t('Transforming_content')}</TextStyled>
                            <ContactButton className='card-btn' icon={WhatsappBtn} label={t('Get_Free_Consultation')} onClick={WhatsApp} />
                        </Box>
                    </Box>
                </Col>
            </Row>
        </ServiceCardBox>
    )
}

export default ServiceCard
