import React from 'react'
import { Link } from 'react-scroll'
import { FooterWapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Container, Row } from 'react-bootstrap'
import ChangeButton from '../../Languages/ChangeButton'
import { NavDataConfig } from '../../Components/sliderData/Data'
import { Box, LazyImage, SpanStyled } from '../../Styled/Elements'
import { FooterInstagram, FooterLinkdin, FooterLogo, FooterTwitter } from '../../Styled/AllImages'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const FowlWeb = () => {
        window.open('https://websites.fowl.sa/', '_blank');
    };
    const date_year = new Date();
    const { t } = useTranslation();
    return (
        <FooterWapper className='container-fluid footer-bg'>
            <Container>
                <Row>
                    <Box className='d-flex align-items-center justify-content-between gap-2 footer-content'>
                        <Box className='footer-logo'>
                            {/* <FooterLogo /> */}
                            <LazyImage src={FooterLogo} />

                        </Box>
                        {/* <Box className='arabic-footer-logo'>
                            <LazyImage src={ArabicFooterLogo} />
                        </Box> */}
                        <Box className='d-flex flex-column justify-content-center gap-3'>
                            <Box className='footer-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key}>
                                        <Link to={item.to}>{t(item.label)}</Link>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='footer-revised'>
                                <SpanStyled className='text-center' size='14px' color='var(--white)' transform='capitalize'>{t('All_Rights_Reserved')} &copy; {date_year.getFullYear()} {t('Quantum_Digital')}. </SpanStyled>
                                <SpanStyled className='text-center' size='14px' color='var(--white)' transform='capitalize'>{t('Developed_by')}
                                    <SpanStyled className='themeTextGradient fowl' size='14px' weight='bold' onClick={FowlWeb}> {t('Fowl')}</SpanStyled>
                                </SpanStyled>
                            </Box>
                        </Box>

                        <Box className='d-flex align-items-center gap-2 footer-social'>
                            <Box><FooterTwitter /></Box>
                            <Box><FooterInstagram /></Box>
                            <Box><FooterLinkdin /></Box>
                        </Box>
                    </Box>
                </Row>
            </Container>
            <ChangeButton />
        </FooterWapper>
    )
}

export default Footer
