
import About from "../Components/sections/about/About"
import Banner from "../Components/sections/banner/Banner"
import Contact from "../Components/sections/contactUs/Contact"
import Services from "../Components/sections/services/Services"
import Projects from "../Components/sections/projects/Projects"
import Portfolio from "../Components/sections/portfolio/Portfolio"
import Testimonial from "../Components/sections/testimonials/Testimonial"

const Index = () => {
    return (
        <>
            <Banner />
            <About />
            <Services />
            <Projects />
            {/* <Portfolio />
            <Testimonial /> */}
            <Contact />
        </>
    )
}

export default Index